
import { Alert, Button, Center, CloseButton, Image, FileButton, 
    Group, Input, Loader, ScrollArea, SimpleGrid, Stack, Text, TextInput, Title } from '@mantine/core';
import { useFocusTrap, useForceUpdate, useLocalStorage, useMediaQuery, useSessionStorage, useViewportSize } from '@mantine/hooks';

import { useForm } from '@mantine/form';

import { useContent, useContentItem } from '../common/content/useContent';
import { useLanguage } from '../common/language/Language';
import { usePubsub } from '../common/pubsub/usePubsub';

import { renderMarkdown } from '../common/content/RenderMarkdown';
import { useCallback, useEffect, useState } from 'react';

//import { duotone } from '@fortawesome/fontawesome-svg-core/import.macro';
import { GlobalAppsettings } from '../GlobalSettings';
import { SecureTestApi } from '../modules/secureTest/api/SecureTestApi';

import { StorageKeys } from '../modules/secureTest/constants/StorageKeys';
import { useNavigate, useParams } from 'react-router-dom';
import { Hero } from './components/Hero';
import { GlobalSecurity } from '../common/security/GlobalSecurity';
import { Http, fetcher } from '../common/Fetcher';
import { TestCategory } from '../common/api/TestCategory';
import { ResultBase } from '../common/api/common';
import useSWR from 'swr';

import { AuthenticatedContent } from '../common/security/components/AuthenticatedContent';

import * as App from 'pubsub-js';

import { LanguageInfo } from '../common/language/LanguageControl';
import { useFingerPrint } from '../common/fingerprint/useFingerPrint';

export type LandingProps = {
};

export const Secure = ({}: LandingProps) => {
    const lang = useLanguage();

    //lang.setLocale("fr");
    
    const { testcategoryId } = useParams();
    
    const navigate = useNavigate()

    const { fingerprint } = useFingerPrint();

    const { accessToken } = GlobalSecurity();

    const [loading, setLoading] = useState(true);
    
    const focusTrapRef = useFocusTrap();
    const [findExam, setFindExam] = useState(false);

    const [currentTestCategory, setCurrentTestCategory] = useSessionStorage<TestCategory>({ 
            key: StorageKeys.TestCategory, defaultValue: undefined, serialize: useCallback((value: any) => JSON.stringify(value), []), });

    const isLargeScreen = useMediaQuery('(min-width: 1200px)');
    const isMobile = useMediaQuery('(max-width: 768px)');

    const [identityError,setidentityError] = useState<string>();
    const [codeError,setCodeError] = useState<string>();

    const [error, setError] = useState<string>(testcategoryId !== undefined ? '': lang.Text('Something went wrong. Please try again later.'));

   const content = useContent({contentID: '7pysNrlF63NTBCwDrkhboA', locale: lang.locale()})

    const [findExamError, setFindExamError] = useState<string>('');

    const [current, setCurrent] = useLocalStorage<SecureTestApi.Session | undefined>({ key: StorageKeys.Session, defaultValue: undefined,
        serialize: useCallback((value: any) => JSON.stringify(value), []),
     });

    const form = useForm({
        initialValues: {  identity: '', code: '', },
        validate: {
          code: (value) => (value.length < 2 ? 'Code must have at least 2 letters or numbers' : null),
          identity: (value) => (value.length < 2 ?  'Invalid Identity': null),
        },
      });
    
    const findExamBooking =() => {
        var validation = form.validate();

        if (!validation.hasErrors)
        {
            setLoading(true);
            console.info('Finding Exam...');
            setFindExam(true);
            setidentityError(undefined);
            setCodeError(undefined);
        }
        else
        {
            console.info(validation.errors);
            setidentityError(validation.errors.email?.toString());
            setCodeError(validation.errors.code?.toString());
        }
    }

    // const [categories, setCategories] = useState<TestCategory[] | undefined>();

    const { data } = useSWR<ResultBase<TestCategory[]>>(
        accessToken!==undefined ?  "/api/client/currentexams" : null,
        () => fetcher("/api/client/currentexams", Http.GET, accessToken), 
        {
           refreshInterval: 0,
           onError: (error) => {
              console.error(error);
           }
       });
    
    useEffect(() => {
        if (data?.success) {
            //setCategories(data.result);
            var find = data.result?.find(x => x.id === testcategoryId);
            if (find !== undefined) {
                console.log("TestCategory -> ", find);
                if (find.language !== lang.locale()) {                
                     //console.log("change lang -> ", find.language);
                }

                App.publish('change.lang', { lang: find.language });
                content.reload(find.language);
            
                setCurrentTestCategory(find);
                setLoading(false);
                
            }
            else
            {
                console.error('Cannot find the test category.');
                setError(lang.Text('Cannot find the test category. Please try again later.'));
                setLoading(false);
            }
        }
        else
        {
            if (data!==undefined) console.error(data.errorMessage);
        }
    }, [data]);

    // useEffect(() => {
    //     if (currentTestCategory!==undefined) {
    //         if (currentTestCategory.language !== undefined && currentTestCategory.language !== lang.locale()) {
    //             App.publish('change.lang', { lang: currentTestCategory.language });
    //         }
    //     }
    // }, [currentTestCategory, lang]);

    // if (currentTestCategory!==undefined) {
    //     if (currentTestCategory.language !== undefined && currentTestCategory.language !== lang.locale()) {                
    //         App.publish('change.lang', { lang: currentTestCategory.language });
    //     }
    // }

    useEffect(() => {
        if (findExam) {
            var info = {
                identity: form.values.identity,
                code: form.values.code,
                type: currentTestCategory?.examType,
                orgId: GlobalAppsettings.OrgId,
                testcategoryId: testcategoryId,
                fingerprint: fingerprint
            }
            fetcher(GlobalAppsettings.BaseUrl + "/api/securetest/initsecuresession",Http.POST, accessToken, info, false, fingerprint)
            .then((data: SecureTestApi.SessionPackage) => {
                  console.info("Session -> ", data);
                    
                  if (data.success) {
                    setCurrent(data.session);
                    console.info('Start onboarding...');
                    navigate(data.session.onboardingUrl);
                    setFindExamError('');
                  }
                  else
                  {
                    setFindExamError(data.errorMessage);
                  }
            }).catch((error) => {
                    setFindExamError(lang.Text("Error connecting to the SecureTest API"));
                    console.error(error);
            }).finally(() => {
                setLoading(false);
                setFindExam(false);
            });
        }
    }, [findExam]);

    useEffect(() => {
       
    },[current]);


    if (current!==undefined) 
    {
        console.info("Current Session ->", current.id);            
        if (current.isOnboarding)
        {
            navigate(current.onboardingUrl);
        }
        else
        {
            setCurrent(undefined);
            //window.location.reload();
        }
    }
    // else
    // {
    //     console.info('No current session');
    // }

    return (<>
        <AuthenticatedContent>
        {loading ? <Center className='h-[50vh]'><Loader variant='dots'></Loader></Center> : <>
        
        {error && <Alert className='bg-red-200  fixed top-4 left-20 right-20 z-20'
             icon={<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-8 h-8">
             <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m0-10.036A11.959 11.959 0 0 1 3.598 6 11.99 11.99 0 0 0 3 9.75c0 5.592 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.31-.21-2.57-.598-3.75h-.152c-3.196 0-6.1-1.25-8.25-3.286Zm0 13.036h.008v.008H12v-.008Z" />
           </svg>} >
            <Group>
                <Text className='font-bold px-4'>{error}</Text>
                <Button variant="subtle" onClick={() => window.location.reload()}>{lang.Text('Retry')}</Button>
            </Group>    
        </Alert>}
            
        <Hero content={content.contentItem} showLogo defaultImageUrl={content.contentItem?.media.url}>
        <Group justify='space-between' preventGrowOverflow wrap="nowrap">
            {/* <Button variant='subtle' color="white"
                onClick={() => {navigate('/testcenter')}}
                ><FontAwesomeIcon icon={duotone('angle-left')}/></Button> */}
            <Text className='text-lg text-slate-400'>{currentTestCategory.description}</Text>
            <LanguageInfo/>
        </Group>
        <Stack 
            className={isLargeScreen ? 'max-w-xl text-white  py-8 px-4' : isMobile ? 'max-w-xl text-white py-8 px-4' : 'max-w-2xl text-white py-8 px-20'}>
              {findExam ? <Center className='h-[50vh]'><Loader variant='dots'></Loader></Center> : <>
    
              
              <ScrollArea className='h-[40vh] pt-2'>
      
                    {renderMarkdown(content?.contentItem?.copy,'','text-white font-extralight')}

             </ScrollArea>
                
                <Input.Wrapper ref={focusTrapRef}
                    id="input-identity" 
                    // withAsterisk
                    label={<span className='text-white'>{lang.Text('Identity')}</span>}
                    description={<span className='text-slate-400 '>
                            {lang.Text('Enter the Identity (NEPH, email etc) you used with your booking')}</span>}
                    error={identityError}
                    >
                    <Input id="input-identity" placeholder={lang.Text('Identity')} {...form.getInputProps('identity')} autoComplete='off' />
                </Input.Wrapper>

                <Input.Wrapper
                    id="input-code"
                    // withAsterisk
                    label={<span className='text-white'>{lang.Text('Code')}</span>}
                    description={<span className='text-slate-400 '>
                            {lang.Text('Enter the code')}</span>}
                    error={codeError}
                    >
                    <Input id="input-code" placeholder={lang.Text('Code')} {...form.getInputProps('code')} autoComplete='off'/>
                </Input.Wrapper>
            
                <Group>
                    <Button color={'blue'} size={'lg'} radius={'xl'} className="my-8" onClick={()=> findExamBooking()}>
                        {lang.Text('Submit')}
                    </Button>    
                </Group>
                {findExamError ? <Alert className='bg-red-200'
                        icon={<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-8 h-8">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m0-10.036A11.959 11.959 0 0 1 3.598 6 11.99 11.99 0 0 0 3 9.75c0 5.592 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.31-.21-2.57-.598-3.75h-.152c-3.196 0-6.1-1.25-8.25-3.286Zm0 13.036h.008v.008H12v-.008Z" />
                      </svg>} 
                        title={<Title order={4}>{lang.Text('Something went wrong')}</Title>} color="red" radius="md">
                        <Text>{lang.Text(findExamError)}</Text>
                </Alert> : <></>}
                <div className='pt-8 '>
                    {renderMarkdown(content?.contentItem?.slug,'','text-slate-100 font-extralight')}
                </div>

                <p className='fixed right-10 bottom-8 text-gray-800'>{fingerprint}</p>
            </>}
        </Stack>
        </Hero>
        </>}
    </AuthenticatedContent></> 
    );
};
