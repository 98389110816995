
import useSWR from "swr";
import { fetcher, Http } from "../Fetcher";
import { GlobalAppsettings } from "../../GlobalSettings";
import { useLanguage } from "../language/Language";
import { ContentItem } from "./api/Content";
import { useLocalStorage, useSessionStorage, useTimeout } from "@mantine/hooks";
import { useState } from "react";

export interface IContentItemProps {
    contentID: string
    locale?: string
    preview?: boolean
}

export const useContent = (props: IContentItemProps) => {
    
    const [lang, setLang] = useState<string | undefined>(props.locale);

    const { data, isLoading, mutate } = useSWR<ContentItem>( 
        () => '/api/content/getcontent?id=' + props?.contentID + '&locale=' + lang
        + '&preview=false'
    , fetcher, {
        refreshInterval: 0,
        keepPreviousData: true
    });
    const reload = (lang?: string) => {
        if (lang!==undefined) {
            setLang(lang);
        }
        mutate();
    }

    return {
        contentItem: data,
        loading: isLoading,
        reload,
    }
}

export const useContentItem = (id: string, cacheTime?: number) => {
    const lang = useLanguage();

    //const [cache, setCache ] = useSessionStorage<ContentItem | undefined>({ key: GlobalAppsettings.AppId +  id, defaultValue: undefined });

    // const { start } = useTimeout(() => {
    //     // console.info("useContentItem: cache cleared -> ", id);
    //     setCache(undefined)
    //     }, cacheTime!==undefined ? cacheTime : 900000);   // default 15 minutes cache time

    const getContentItem = async ( contentID: string, locale? : string, preview?: boolean) : Promise<ContentItem>  => {
        let l = locale ? locale : lang.locale();
        
        // if (cache!==undefined) {
        //     console.info("useContentItem: cache hit -> ", id);
        //     return cache;
        // } 
        // else {
            return fetch(GlobalAppsettings.BaseUrl + '/api/content/getcontent?id=' + contentID + '&locale=' + l
            + '&preview=' + preview, {
                method: "GET",
                headers: {
                "Content-Type": "application/json",
                }
            })
            .then((response) => response.json()).then((data) => { 
                // setCache(data);
                // start();
                // console.info("useContentItem: refresh -> ", id);
                return data as ContentItem; 
            })
        // }
    }

    return {
        getContentItem
    }
}
