
import {  useCallback, useEffect, useMemo, useState } from 'react';
import { useContent, useContentItem } from '../common/content/useContent';
import { useLanguage } from '../common/language/Language';

import { Button, Center, FileButton, Group, Loader, Stack, Title, Image, Text, Alert } from '@mantine/core';
import { readSessionStorageValue, useFocusTrap, useForceUpdate, useLocalStorage, useMediaQuery, useSessionStorage } from '@mantine/hooks';
import { useNavigate } from 'react-router-dom';



import { StorageKeys } from '../modules/secureTest/constants/StorageKeys';
import { SecureTestApi } from '../modules/secureTest/api/SecureTestApi';
import { DateTime } from 'luxon';
import { fetcher, Http } from '../common/Fetcher';

import useSWR, { mutate } from 'swr';
import { OnboardingStep } from '../modules/onboarding/OnboardingStep';
import { useSubscribe } from '../common/pubsub/usePubsub';

import { Hero } from './components/Hero';
import { TestCategory } from '../common/api/TestCategory';
import { GlobalSecurity } from '../common/security/GlobalSecurity';
import { ContentItem } from '../common/content/api/Content';

import * as App from 'pubsub-js';
import { LanguageInfo } from '../common/language/LanguageControl';
import { useFingerPrint } from '../common/fingerprint/useFingerPrint';

export type PageProps = {
    
};

export const Onboarding = ({}: PageProps) => {
    const lang = useLanguage();

    const navigate = useNavigate()

    const {accessToken, isLoggedIn} = GlobalSecurity();

    const { fingerprint } = useFingerPrint();
   
    const [content, setContent] = useState<ContentItem | undefined>(undefined);
    const { getContentItem } = useContentItem('onboarding.content');
    
    const isLargeScreen = useMediaQuery('(min-width: 1200px)');
    const isMobile = useMediaQuery('(max-width: 768px)');

    const [error, setError] = useState<string | undefined>();

    const [currentStep, setCurrentStep] = useState(0);

    const [loading, setLoading] = useState(false);

    const [onboardingId, setOnboardingId] = useState<string | undefined>(undefined);

    const currentTestCategory  = useMemo<TestCategory | undefined>(() => {       
        let val = readSessionStorageValue<TestCategory>({ key: StorageKeys.TestCategory, 
            defaultValue: undefined});

        console.info("testcategory ->", val);

        if (val.language !== undefined && val.language !== lang.locale()) {                
            App.publish('change.lang', { lang: val.language });
        }
    
        return val;
    }, []);

    // useEffect(() => {
    //     if (currentTestCategory!==undefined) {
    //         if (currentTestCategory.language !== undefined && currentTestCategory.language !== lang.locale()) {                
    //             App.publish('change.lang', { lang: currentTestCategory.language });
    //         }
            
    //     }
    // }, [currentTestCategory, lang]);


    // if (currentTestCategory!==undefined) {
    
    //     if (currentTestCategory.language !== undefined && currentTestCategory.language !== lang.locale()) {                
    //         App.publish('change.lang', { lang: currentTestCategory.language });
    //     }
    // }
   
    const [current, setCurrent] = useLocalStorage<SecureTestApi.Session | undefined>({ key: StorageKeys.Session, 
        defaultValue: undefined,serialize: useCallback((value: any) => JSON.stringify(value), []), });

    const { data: onboarding, isLoading, mutate: reload } = useSWR<SecureTestApi.Onboarding>(
        current?.token !== undefined && onboardingId!==undefined ? "/api/securetest/getonboarding?id=" + onboardingId : null, 
        (url) => fetcher(url, Http.GET, current?.token, undefined, false, fingerprint),
      {
          refreshInterval: 500,
          onError: (error) => {
            console.error("Error ->", error);
            setError(lang.Text('Something went wrong. Please try again later.'));
          }
      });

    useEffect(() => {
        if (current!==undefined) {
            setError(undefined);
            if (current.isRunning) {
                navigate("/exam");
            }
            if (current.isOnboarding && onboardingId === undefined) {
                console.info("Onboarding -> ", current.onboardingId);
                setOnboardingId(current?.onboardingId);
            }
        }
        else
        {
            setError(lang.Text('No session found. Please start a new session.'));
        }
    },[current]);

    useEffect(() => {
        console.info("Onboarding -> ", onboarding);
        if (onboarding!==undefined) {
            let index  = 0;
            onboarding.steps.forEach((step, key) => {
                if (step.isCompleted) {
                    index++;
                    setCurrentStep(index);
                }
            });

            var step = onboarding.steps[currentStep];

            if (step!==undefined) {
                let contentRef = step.contentRef;

                getContentItem(contentRef).then((result) => {
                    console.info(contentRef, " -> ",result);
                    setContent(result);
                }).catch((error) => {
                    console.error(error);
                    setError(lang.Text('Something went wrong. Please try again later.'));
                }).finally(() => {
                    setLoading(false);
                });
            }
        }
        if (onboarding?.isCompleted)
        {
            console.info("Onboarding is completed: ", onboarding);
            startExam();
        }
    },[onboarding, onboarding?.steps, onboarding?.isCompleted]);

    const startExam = () => {
        if (onboarding!==undefined && onboarding.isCompleted) {
            setLoading(true);
            var request = {
                id: current?.id,
                examType: currentTestCategory?.examType,
            };

            if (currentTestCategory?.examType === 'francecode.testcenter') {

                console.info("Start Secure exam ->", current?.id, currentTestCategory?.examType);
                

                fetcher<SecureTestApi.SessionPackage>('/api/securetest/StartExamSecure', Http.POST, accessToken, request,
                    false, fingerprint
                )
                .then((result) => {
                    if (result.success) {
                        console.info("Exam start ->", result.session);
                        setCurrent(result.session);
                        navigate(currentTestCategory?.examUrl ?? '/exam');
                        setLoading(false);
                    }
                    else
                    {
                        setError(result.errorMessage);
                        setLoading(false);
                    }
                }).catch((error) => {
                    console.error("StartExam Error ->", error);
                    setError(lang.Text('Something went wrong. Please try again later.'));
                    setLoading(false);
                });
            }
            else {
             
                console.info("Start exam ->", current?.id, currentTestCategory?.name);
                
                fetcher<SecureTestApi.SessionPackage>('/api/securetest/StartExam', Http.POST, current?.token, request,
                    false, fingerprint
                )
                .then((result) => {
                    console.info("Exam start ->", result);

                    if (result.success) {
                        setCurrent(result.session);
                        navigate(currentTestCategory?.examUrl ?? '/exam');
                        setLoading(false);
                    }
                    else
                    {
                        setError(result.errorMessage);
                        setLoading(false);
                    }
                }).catch((error) => {
                    console.error("StartExam Error ->", error);
                    setError(lang.Text('Something went wrong. Please try again later.'));
                    setLoading(false);
                });


            }
        }
    }

    useSubscribe('start.exam', () => {
        startExam();
    });

    useSubscribe('step.next', () => {
        if (onboarding!==undefined) {
            if (onboarding.steps[currentStep].isCompleted) {
                if (currentStep < onboarding.steps.length-1) {
                    setCurrentStep(currentStep + 1);
                }
            }
            else
            {
                setError(lang.Text('Please complete the current step before continuing.'));
            }
        }
    });

    useSubscribe('onboarding.reload', (msg: string, data: any) => {
        reload();
    });

    const nextStep = () => {
        if (onboarding!==undefined) {
            if (onboarding.steps[currentStep].isValid) {
                if (currentStep < onboarding.steps.length-1) {
                    setCurrentStep(currentStep + 1);
                }
            }
            else
            {
                setError(lang.Text('Please complete the current step before continuing.'));
            }
        }
    }

    const retryStep = () => {
        reload();
    }

    const renderOnboardingSteps = () => {
        
        if (onboarding!==undefined && onboarding.steps.length > 0) {
            try {
                let step =
                <OnboardingStep key={onboarding.steps[currentStep].order} onboardingId={onboarding.id} token={current?.token}
                    step={onboarding.steps[currentStep]} reset={resetOnboarding} next={nextStep} retry={retryStep}>
                </OnboardingStep>
                return step;
            } catch (error) {
                setCurrentStep(0);
            }
        }
        else
        return <></>;
    }

    const resetOnboarding = () => {
        setOnboardingId(undefined);
        setCurrent(undefined);
        navigate("/");
    }

    return (<>
    <Hero content={content} defaultImageUrl={currentTestCategory?.imageUri ? currentTestCategory.imageUri : ''}
    showLogo
    >
    {error ? <Alert className='bg-red-200'
                    icon={<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-8 h-8">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m0-10.036A11.959 11.959 0 0 1 3.598 6 11.99 11.99 0 0 0 3 9.75c0 5.592 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.31-.21-2.57-.598-3.75h-.152c-3.196 0-6.1-1.25-8.25-3.286Zm0 13.036h.008v.008H12v-.008Z" />
                  </svg>
                  } 
                    title={<Title order={4}>{lang.Text('Something went wrong')}</Title>} color="red" radius="md">
                    <Text>{error}</Text>
                    <Group  justify='end'>
                        <Button className="mt-4" leftSection={<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99" />
                                                        </svg>
                                    }
                            variant='filled' onClick={() => window.location.reload()}>{lang.Text('Retry')}</Button>
                        <Button className="mt-4"
                            variant='subtle' onClick={() => resetOnboarding()}>{lang.Text('Go to startpage')}</Button>
                    </Group>
                </Alert> : <>
    {loading ? <Center h={'30vh'}> <Loader size={'md'}/></Center> : 
    <div
        className={isLargeScreen ? 'w-full text-whitepx-4' 
                : isMobile ? 'max-w-x1 text-white px-4' : ' text-white px-20'}>
        {isLoading ? <Center className='h-[50vh]'><Loader ></Loader></Center> 
        : <>
        {}
            {current && <>
                {onboarding?.isCompleted ? <></> : <>
                <Group justify='space-between'>
                    <Title className="text-3xl text-white py-6">{lang.Text('Onboarding')}</Title>
                    <Text className='text-gray-500'>
                            {lang.Text('Step')}{' '}
                            {currentStep+1}/{onboarding?.steps.length}
                    </Text>
                    <LanguageInfo/>
                </Group>
                    {onboarding && <>
                        {renderOnboardingSteps()}
                    </>}
                </>}
            </>}
        </>}
    </div>}
    </>}
    </Hero>
    </> 
    );
};


