import { useEffect, useState } from 'react';

import * as App from 'pubsub-js';

import { Drawer, Menu, Text, Group, SimpleGrid, Button, Input, Title, ActionIcon  } from '@mantine/core';
import useSWR from 'swr';
import { fetcher, Http } from '../Fetcher';
import { Language, useLanguage } from './Language';
import { GlobalAppsettings } from '../../GlobalSettings';
import { KeyWord, LanguageMeta } from '../api/Language';


// Image imports
// import lang_sv from './img/sweden.png'
// var lang_eng from './img/united-kingdom.png'
// var ang_usa from './img/united-states-of-america.png'
// var lang_den from './img/denmark.png'
// var lang_nor from './img/norway.png'
// var lang_fin from './img/finland.png'
// var  lang_fr from './img/france.png'
// var lang_sp from './img/spain.png'

var lang_sv = require('./img/sweden.png');
var lang_eng = require('./img/united-kingdom.png');
var lang_usa = require('./img/united-states-of-america.png');
var lang_fr = require('./img/france.png');


export const getFlags = (lang: string) => {
    let currentLang = lang_sv;
    switch (lang.toLowerCase()) {
        case 'sv':
            currentLang = lang_sv;
            break;
        case 'en':
        case 'en-gb':
            currentLang = lang_eng;
            break;
        case 'en-us':
            currentLang = lang_usa;
            break;
        case 'fr':
            currentLang = lang_fr;
            break;
    }
    return currentLang;
}


export const LanguageInfo = () => {
    const lang = useLanguage();

    return <img className="h-8 w-8 rounded-full" src={getFlags(lang.locale())} alt=''/>
}

export const LanguageControl = () => {
  const lang = useLanguage();
  const [manage, setManage] = useState(false);
  const [loading, setLoading] = useState(true);
  const [current, setCurrent] = useState<LanguageMeta>();
  const [langKeys, setLangKeys] = useState<KeyWord[]>([]);
  
    
    const { data  } = useSWR<LanguageMeta[]>(
        '/api/app/GetLanguagesMeta'
    , fetcher, {
        refreshInterval: 0, 
        // revalidateIfStale: true,
        revalidateOnFocus: false,
        // revalidateOnReconnect: true,
        onError: (error) => {
          setLoading(false);
        },
        onSuccess : (data) => {
          setLoading(false);
        }
    });

    useEffect(() => {
      if (data===undefined || data.length <= 0) {
      }
      else
      {
        let currentMeta = data.find((m) => m.base);
        let index = data.findIndex((m) => m.language === Language.GetLocale());
        
        if (index >= 0) {
            currentMeta = data[index];
        }
        setCurrent(currentMeta);
      }
    }, [data]);

   
    const viewKeys = () => {  
      setLangKeys(lang.UnMapped);
      setManage(true);
    }

    const saveList = ()  => {  
      langKeys.forEach((k) => {
        if (k.key!==k.value)
        {
          console.warn("saving key: " + k.key + " value: " + k.value);
          //Language.AddMissingText(k.key, k.value);
        }
      });
      setManage(false);
      Language.ResetUnMapped();
      window.location.reload();
    }

    return (
      <>
       <Menu
        position='top'
        width={250}
      >
      <Menu.Target>
       <ActionIcon size="xl" radius="xl" variant='transparent' loading={loading}>
        <img className="h-8 w-8 rounded-full" src={getFlags(current ? current.language : '')} alt=''/>  
       </ActionIcon>
      </Menu.Target>
      <Menu.Dropdown>
        <Menu.Label>{lang.Text('Languages')}</Menu.Label>
        {data?.map((item,key) => {
            return (
                <Menu.Item key={key} 
                    leftSection={<img className="h-8 w-8 rounded-full" src={getFlags(item.language)} alt=''/>}
                    onClick={() => App.publish('change.lang', { lang: item.language })}
                    >
                    {item.name}
                </Menu.Item>
            )
        })}
        {GlobalAppsettings.IsDevelopment ? <Menu.Label>Development</Menu.Label> : <></>}
        {GlobalAppsettings.IsDevelopment ? <Menu.Item onClick={() => viewKeys()}>Manage</Menu.Item> : <></>}
      </Menu.Dropdown>
    </Menu>

    <Drawer
        opened={manage}
        onClose={() => setManage(false)}
        title={<><Text size='xl' variant="gradient"
        gradient={{ from: 'indigo', to: 'cyan', deg: 45 }}>Devtools</Text></>}
        padding="xl"
        size="xl"
      >
      <div className="flex flex-col absolute inset-5 mt-12">
        <div className="flex-1">
        <Title order={4}
            >Language</Title> 
          <SimpleGrid cols={2} className="pt-4">
            {langKeys.map((item, index) => {
              return (
                <>
                <div>{item.key}</div>
                <Input key={index}
                  value={item.value}
                  onChange={(e: any) => {
                    let newList = [...langKeys];
                    newList[index].value = e.target.value;
                    setLangKeys(newList);
                  }}           
                />
                </>
              )
              })}
            </SimpleGrid>

            <Group className='pt-6' align='right'>
            <Button size='md' color={'green'}
              //leftSection={ <FontAwesomeIcon  icon={regular("save")}/> } 
              onClick={() => {saveList()}}
              >Save</Button>
            </Group>
        </div>
        </div>
      </Drawer>
    </>
  );
}