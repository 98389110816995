

import * as Sentry from "@sentry/react";
import React from 'react';
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from "react-router-dom";

import packageJson from '../package.json';

console.info(packageJson.version);

const { REACT_APP_IS_DEV } = process.env;
const { REACT_APP_BASE_URL } = process.env;
const { REACT_APP_DEFAULT_LANGUAGE } = process.env;
const { REACT_APP_AUTH_PROVIDER } = process.env;

export class GlobalAppsettings {

  protected static api_key: string = "*l1^-;L^p1|G%956n_*Q520b";

  static AppId: string = "yc-enterprise-securetest-" + packageJson.version;

  static AppInsights: string = "9f260433-df6f-4f7e-9ba4-981ab26da62a";

  static IsDevelopment: boolean =  REACT_APP_IS_DEV ? (REACT_APP_IS_DEV.toLowerCase() === 'true') : false;

  static BaseUrl: string = REACT_APP_BASE_URL ? REACT_APP_BASE_URL : 'https://192.168.137.105:7288';

  static InterCom: string = "vyoqj5te";

  static AuthProvider: string = REACT_APP_AUTH_PROVIDER ? REACT_APP_AUTH_PROVIDER : 'auth0';

  static DefaultLang: string = REACT_APP_DEFAULT_LANGUAGE ? REACT_APP_DEFAULT_LANGUAGE : 'en';

  static StripeKey: string = "pk_test_51IsRksABc38EtH9J52zPbml0d4WYIB7pkOkYZY71qmdeSNp9YkAiCbCFfj7f8FvwBVYVV8BK8uGaEfb6qFUbJNbZ00z4mii47Z";

  static Audience: string = `https://api.portal.youcap.com`;

  static FocusLockCount = 5;
  
  static OrgId: string = "internal";
}

const APP_USER = GlobalAppsettings.AppId + "-user";
const APP_USER_SELECTED_PROGRAM = APP_USER + "-selected-program";
const APP_LANGUAGE = GlobalAppsettings.AppId + "-language";
const APP_CURRENT_LANGUAGE = GlobalAppsettings.AppId + "-current-language";

console.info(REACT_APP_BASE_URL);
console.info(REACT_APP_DEFAULT_LANGUAGE);

export const StorageKeys = {
  APP_USER,
  APP_USER_SELECTED_PROGRAM,
  APP_LANGUAGE,
  APP_CURRENT_LANGUAGE,
};

// export const appInsights = new ApplicationInsights({ config: {
//   instrumentationKey: GlobalAppsettings.AppInsights
// } });

if (GlobalAppsettings.IsDevelopment) {
  
  console.info("Development Mode");

}
else{
  
  console.log = function () {};
  console.info = function () {};
  console.warn = function () {};
  console.error = function (...data: any[]) {
    try {
      fetch(GlobalAppsettings.BaseUrl + "/api/securetest/reportError", {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          message: data.join(' '),
        })
      });  
    } catch (error) {}

    return true;
  };
}

export const localStorageCacheProvider = () => {
  const map = new Map(JSON.parse(localStorage.getItem(GlobalAppsettings.AppId + '-app-cache') || '[]'))
  window.addEventListener('beforeunload', () => {
    const appCache = JSON.stringify(Array.from(map.entries()))
    localStorage.setItem(GlobalAppsettings.AppId + '-app-cache', appCache)
  })
  return map
}

export const sessionStorageCacheProvider = () => {
  const map = new Map(JSON.parse(sessionStorage.getItem(GlobalAppsettings.AppId + '-app-cache') || '[]'))
  window.addEventListener('beforeunload', () => {
    const appCache = JSON.stringify(Array.from(map.entries()))
    sessionStorage.setItem(GlobalAppsettings.AppId + '-app-cache', appCache)
  })
  return map
}
